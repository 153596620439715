@font-face {
	font-family: 'Poppins';
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: local('Poppins'), url('../fonts/Poppins-Regular.woff') format('woff'),
		url('../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src: local('Poppins'), url('../fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: local('Poppins'), url('../fonts/Poppins-Bold.ttf') format('truetype');
}

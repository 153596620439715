@import 'variables';
@import 'typography';

html,
body {
	height: 100%;
	padding: 0;
	margin: 0;
	overflow: hidden;
	font-size: 1.04vw;
	background-size: 100%;
	background-color: #000;
	font-family: var(--font-family);
	user-select: none;
}

// Hide scrollbars
::-webkit-scrollbar {
	display: none;
}

* {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

:root {
	/* Brand */
	--dn-active-blue: #00508f;
	--dn-core-blue: #0095da;
	--dn-blue-hover: #39b0e7;
	--dn-stream-red: #b50800;
	--dn-stream-hover: #d41b11;
	--dn-live-red: #d00019;
	--dn-secondary-blue: #1976d2;
	--dn-aqua: #00ffeb;
	--sm-grey: #51717e;
	--sm-pink: #d53e8a;
	--sm-icon-pink: #c64c88;
	--sm-icon-teal: #668f9e;

	/* Blacks */
	--bg: #000000;
	--black: #000000;
	--black95: rgba(0, 0, 0, 0.95);
	--black90: rgba(0, 0, 0, 0.9);
	--black85: rgba(0, 0, 0, 0.85);
	--black80: rgba(0, 0, 0, 0.8);
	--black75: rgba(0, 0, 0, 0.75);
	--black70: rgba(0, 0, 0, 0.7);
	--black60: rgba(0, 0, 0, 0.6);
	--black40: rgba(0, 0, 0, 0.4);

	/* Whites */
	--white: #ffffff;
	--white-tv: #f1f1f1;
	--white-tv-inactive: rgba(--white-tv, 0.6);
	--white95: rgba(255, 255, 255, 0.95);
	--white80: rgba(255, 255, 255, 0.8);
	--white70: rgba(255, 255, 255, 0.7);
	--white65: rgba(255, 255, 255, 0.65);
	--white60: rgba(255, 255, 255, 0.6);
	--white50: rgba(255, 255, 255, 0.5);
	--white40: rgba(255, 255, 255, 0.4);
	--white30: rgba(255, 255, 255, 0.3);
	--white25: rgba(255, 255, 255, 0.25);
	--white20: rgba(255, 255, 255, 0.2);
	--white7: rgba(255, 255, 255, 0.07);
	--white90: #eee;
	--white75: #d9d9d9;

	/* Greys */
	--grey1: #292929;
	--grey2: #232323;
	--grey2-50: rgba(35, 35, 35, 0.5);
	--grey2-70: rgba(35, 35, 35, 0.7);
	--grey2-90: rgba(35, 35, 35, 0.9);
	--grey2-95: rgba(35, 35, 35, 0.95);
	--grey3: #121212;
	--grey4: #8e8e93;
	--grey5: #4a4a4a;
	--grey6: #2f2f2f;
	--grey7: #9b9b9b;
	--grey8: #1a1a1a;
	--grey8-85: rgba(26, 26, 26, 0.85);
	--grey8-50: rgba(26, 26, 26, 0.5);
	--grey9: #686868;
	--grey10: #1e1e1e;
	--grey11: #808080;
	--grey12: #2a2a2a;
	--grey13: #dddddd;
	--grey14: #808080;
	--grey15: #2a2a2a;
	--grey16: #0e0e0e;

	/* BoxOffice */
	--bo-green: #256519;

	/* Error */
	--error-red: #ff7277;
	--error-red2: #c93535;

	/* TYPOGRAPHY */
	/* --------------------------------------------------------------------------- */

	--font-family: 'Poppins', sans-serif;
	--font-size-base: 100%;
	--line-height-base: 1.5;

	--font-xs: 0.65vw;
	--font-xs-1: 0.72vw;
	--font-xs-2: 0.78vw;
	--font-sm: 0.85vw;
	--font-sm-2: 0.91vw;
	--font-md: --font-size-base;
	--font-md-2: 1.17vw;
	--font-md-3: 1.3vw;
	--font-lg: 1.43vw;
	--font-lg-2: 1.56vw;
	--font-lg-3: 1.95vw; /* 30 */
	--font-xl: 2.47vw;
	--font-xl-1: 2.86vw;
	--font-xl-2: 3.26vw;
	--font-xl-3: 3.91vw;
	--font-xl-4: 4.43vw;

	/* MEDIA QUERIES */
	/* --------------------------------------------------------------------------- */

	--bp-xs: 23.44vw; /* 360 */
	--bp-sml: 26.04vw; /* 400 */
	--bp-sml-2: 35.16vw; /* 540 */
	--bp-sml-3: 39.06vw; /* 600 */
	--bp-med: 50vw; /* 768 */
	--bp-med-1: 58.59vw; /* 900 */
	--bp-med-2: 66.67vw; /* 1024 */
	--bp-med-3: 78.13vw; /* 1200 */
	--bp-med-4: 88.54vw; /* 1360 */
	--bp-lrg: 91.15vw; /* 1400 */
	--bp-lrg-2: 104.17vw; /* 1600 */
	--bp-xl: 117.19vw; /* 1800 */
	--bp-xl-2: 130.21vw; /* 2000 */
	--bp-xl-3: 149.74vw; /* 2300 */

	--smart-720: 83.33vw;
	--smart-1080: 125vw;

	--mq-sml-3: 39vw; /* 599 */
	--mq-med: 49.93vw; /* 767 */

	/* Buttons + Links */
	--link: var(--white);
	--link-hover: var(--dn-core-blue);
	--link-blue: var(--dn-core-blue);
	--link-blue-hover: var(--dn-blue-hover);
	--sub-link: var(--grey4);
	--sub-link-hover: var(--white);
	--btn-font-weight: 500;

	/* Primary */
	--btn-bg-primary: var(--white);
	--btn-border-primary: var(--white);
	/* Secondary */
	--btn-bg-secondary: transparent;
	--btn-border-secondary: var(--white);
	/* Stream */
	--btn-bg-stream: var(--dn-stream-red);
	--btn-border-stream: var(--dn-stream-red);
	--btn-bg-stream-hover: var(--dn-stream-hover);
	/* Disabled */
	--btn-bg-disabled: var(--white20);
	--btn-border-disabled: transparent;
	/* Blue */
	--btn-bg-blue: var(--dn-core-blue);
	--btn-border-blue: var(--dn-core-blue);
	--btn-bg-blue-hover: var(--dn-blue-hover);
	/* Showmax Grey */
	--btn-bg-smgrey: var(--sm-grey);
	--btn-border-smgrey: var(--sm-grey);
	--btn-bg-smgrey-hover: #658d9d;
	/* Showmax Pink */
	--btn-bg-smpink: var(--sm-pink);
	--btn-border-smpink: var(--sm-pink);
	/* Default Hover */
	--btn-bg-hover: var(--dn-core-blue);

	/* Borders */
	--card-border: var(--grey1);
	--card-border-2: var(--grey5);

	/* Footers */
	--footer-now: var(--grey2);
	--footer-mc: var(--grey3);
}
